import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const ModernSlaveStatementPage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Section>
          <SectionHeading>Modern Slave Statement</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              <h2>Introduction</h2>
            </p>
            <p>
              <span>
                PJK MARKETING LTD is committed to ethical business practices and
                ensuring that modern slavery and human trafficking have no place
                in our operations or supply chain. This Modern Slavery Statement
                outlines our efforts to combat these issues and fulfill our
                obligations under the Modern Slavery Act 2015.
              </span>
            </p>
            <p>
              <h2>Our Business</h2>
            </p>
            <p>
              <span>
                PJK MARKETING LTD is a marketing business based in remotely but
                legally registered in England. We are dedicated to upholding the
                highest standards of integrity and ethics in all aspects of our
                business.
              </span>
            </p>
            <p>
              <h2>Our Commitment</h2>
            </p>
            <p>
              <span>
                We recognize that modern slavery is a global issue that affects
                vulnerable individuals and communities. We are committed to
                taking proactive steps to prevent and address any instances of
                modern slavery and human trafficking within our operations and
                supply chain.
              </span>
            </p>
            <p>
              <h2>Our Supply Chain</h2>
            </p>
            <p>
              <span>
                PJK MARKETING LTD engages with a variety of suppliers and
                partners to deliver our services. We expect all of our suppliers
                and business partners to adhere to the same high standards of
                ethical conduct and to operate in compliance with all applicable
                laws and regulations.
              </span>
            </p>
            <p>
              <h2>Our Actions</h2>
            </p>
            <p>
              <span>
                To fulfill our commitment to combat modern slavery, we have
                taken the following actions:
              </span>
            </p>
            <ul>
              <li>
                <span>
                  Supplier Due Diligence: We conduct due diligence on our
                  suppliers to assess the risk of modern slavery and ensure
                  compliance with ethical and legal standards.
                </span>
              </li>
              <li>
                <span>
                  Supplier Code of Conduct: We have established a Supplier Code
                  of Conduct that outlines our expectations regarding labor
                  practices, human rights, and ethical conduct. This code is
                  communicated to all our suppliers.
                </span>
              </li>
              <li>
                <span>
                  Employee Training: We provide training to our employees to
                  raise awareness of modern slavery issues and equip them with
                  the knowledge to identify and report any concerns.
                </span>
              </li>
              <li>
                <span>
                  Reporting Mechanisms: We have established channels for
                  reporting concerns related to modern slavery, including an
                  anonymous whistleblowing hotline.
                </span>
              </li>
              <li>
                <span>
                  Risk Assessment: We regularly assess the risk of modern
                  slavery in our operations and supply chain and take
                  appropriate steps to mitigate these risks.
                </span>
              </li>
            </ul>
            <p>
              <h2>Future Steps</h2>
            </p>
            <p>
              <span>
                We are committed to continuous improvement in our efforts to
                combat modern slavery. In the future, we will:
              </span>
            </p>
            <ul>
              <li>
                <span>Strengthen our due diligence processes.</span>
              </li>
              <li>
                <span>
                  Collaborate with industry partners to share best practices.
                </span>
              </li>
              <li>
                <span>
                  Monitor the effectiveness of our actions and make necessary
                  adjustments.
                </span>
              </li>
            </ul>
            <p>
              <h2>Conclusion</h2>
            </p>
            <p>
              <span>
                PJK MARKETING LTD is dedicated to conducting its business
                responsibly and ethically. We will not tolerate modern slavery
                or human trafficking in any form within our operations or supply
                chain. This Modern Slavery Statement reflects our ongoing
                commitment to combatting these issues and upholding human
                rights.
              </span>
            </p>
            <p>
              <h2>Contact Information</h2>
            </p>
            <p>
              <span>
                For inquiries related to this Modern Slavery Statement, please
                contact us at:
              </span>
            </p>
            <p>
              <span>PJK MARKETING LTD</span>
              <span>Hope Cottage Draycott, Claverley,</span>
              <span>Wolverhampton, Shropshire,</span>
              <span>England, WV5 7EA</span>
              <span>Email: info@ai-productreviews.com</span>
            </p>
            <p>
              <span>Date of Statement: September 20, 2023</span>
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default ModernSlaveStatementPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Modern Slave Statement | AI Product Reviews"}
      description={
        "Our dedication to ethical AI practices. Discover our modern slave statement and responsible AI commitment."
      }
      pathname={location.pathname}
    />
  );
};
